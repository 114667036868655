import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { mergePrismicPreviewData } from 'gatsby-source-prismic'
import NotFoundPage from 'pages/404'

/* Component imports */
import Layout from 'components/Layout'
import Reviews from 'components/Reviews'
import SlicesArea from 'components/SlicesArea'
// import InstagramFeed from 'components/InstagramFeed' /* Disabling module due to deprication of API method. */
import BookingForm from 'components/BookingForm'
import BookingFormBenefits from 'components/BookingForm/BookingFormBenefits'
import BookingFormBenefitsCompact from 'components/BookingForm/BookingFormBenefitsCompact'
import BookingFormRatings from 'components/BookingForm/BookingFormRatings'
import BookingFormRatingsCompact from 'components/BookingForm/BookingFormRatingsCompact'
import Map from '../components/Map'

const IS_BROWSER = typeof window !== 'undefined'

/**
 * Renders the homepage page component
 * Page is built from a combination of fixed fields and repeatable slices
 *
 * @author Mike Davies <mike@batch.nz>
 * @author Scott Gimblett <scott@batch.nz>
 * @author Josh Smith <josh@batch.nz>
 */
class IndexPage extends React.Component {
  render() {
    const { location, data: staticData } = this.props

    const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__
    const data = mergePrismicPreviewData({ staticData, previewData })

    const homePage = data.prismicHome.data
    const slices = homePage.body || []
    const heroSlices = homePage.body1 || []
    if (homePage == null) return <NotFoundPage location={location} />
    return (
      <Layout
        metaTitle={homePage.meta_title}
        metaDescription={homePage.meta_description}
        location={location}
        heroSlices={heroSlices}
      >
        {/* <div className="relative z-20 w-full container max-w-none lg:mb-1"> */}
        {/* <div className="w-full bg-flush-left bg-sand"> */}
        {/* <div className="relative z-20 pt-8 md:pt-10 lg:py-16 2xl:pr-18"> */}
        <div className="bg-sand z-20 pt-6 pb-4 -mt-1">
          <div className="container">
            <BookingForm />
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}

        {slices.length && <SlicesArea slicesData={slices} />}

        {/** Disabling module due to deprication of API method.
          <InstagramFeed />
        */}
        {/* <Reviews /> */}
        <Map />
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
}

/**
 * Defines the graphql query for the hompage
 *
 * Note: this utilises the same 'slices' structure as the generic page component.
 * Due to shortcoming in the plugin we're using this query is copy'n'pasted at this stage.
 *
 * Any updates to the slices data structure should be reflected across other page components using this data
 * or the slices components will stop working.
 *
 * @see templates/pages.js
 * @type {string}
 */
export const query = graphql`
  query {
    prismicHome {
      data {
        meta_description
        meta_title
        title
        body {
          ... on PrismicHomeBodyHeading {
            slice_type
            primary {
              heading
            }
          }
          ... on PrismicHomeBodyContent {
            slice_type
            primary {
              heading
              rich_text {
                html
              }
              button_colour
              button_text
              button {
                link_type
                uid
                url
              }
            }
          }
          ... on PrismicHomeBodyLargeImage {
            slice_type
            primary {
              image_width
              modal_video_id
              cover_video_direct_link
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
                alt
              }
            }
          }
          ... on PrismicHomeBodyImageContent {
            slice_type
            primary {
              button {
                link_type
                uid
                url
              }
              button_colour
              button_text1
              heading
              layout
              image_overlay_text
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              rich_text {
                html
              }
            }
          }
          ... on PrismicHomeBodyLinkPanel {
            slice_type
            items {
              panel_title
              panel_link {
                uid
                url
                link_type
              }
              panel_image {
                localFile {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomeBodyTwoImage {
            slice_type
            primary {
              image_1 {
                localFile {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              image_2 {
                localFile {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomeBodyLocations {
            slice_type
            items {
              activity_location {
                longitude
                latitude
              }
              activity_name
            }
            primary {
              description
              title1
            }
          }
          ... on PrismicHomeBodyAppInformation {
            slice_type
            primary {
              layout
              heading
              button_text
              button {
                link_type
                uid
                url
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
                alt
              }
              rich_text {
                html
              }
            }
            items {
              feature_description
              feature_icon {
                localFile {
                  url
                }
              }
            }
          }
          ... on PrismicHomeBodyAccordion {
            slice_type
            primary {
              accordion_heading
            }
            items {
              accordion_item_title
              accordion_item_content {
                html
              }
            }
          }
          ... on PrismicHomeBodyRooms {
            slice_type
            main: primary {
              text {
                text
              }
              title1 {
                text
              }
            }
          }
        }
        body1 {
          ... on PrismicHomeBody1Image {
            slice_type
            primary {
              heading
              heading_strip_colour
              hero_link_text
              sub_heading_a
              sub_heading_b
              sub_heading_strip_colour
              hero_link {
                uid
                url
                link_type
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomeBody1Video {
            slice_type
            primary {
              heading
              heading_strip_colour
              hero_link_text
              sub_heading_a
              sub_heading_b
              sub_heading_strip_colour
              video_direct_link
              hero_link {
                uid
                url
                link_type
              }
              poster {
                url
              }
            }
          }
        }
      }
    }
    prismicGlobals {
      data {
        rating {
          rating
          source
        }
      }
    }
  }
`

export default IndexPage
