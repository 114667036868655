import React, { useState, useEffect } from 'react'
import Calendar from 'react-calendar'
import dayjs from 'dayjs'

/**
 * Booking Form component
 * Handles the selection of number of guests and check in/out dates and
 * generates an external booking URL.
 *
 * @author Josh Smith <josh@batch.nz>
 */
const BookingForm = () => {
  /**
   * Date formats
   * @type string
   */
  const displayDateFormat = `D MMM YY`
  const bookingDateFormat = `YYYY-MM-DD`

  /**
   * External booking service URL
   * @type string
   */
  const bookingUrl = `https://book-directonline.com/properties/mipadhoteldirect`

  /**
   * Stores the calendar wrapper reference
   * @type object
   */
  let calendarWrapperRef

  /**
   * Initialize state
   */
  const [numPeople, setNumPeople] = useState(``)
  const [bookingDates, setBookingDates] = useState([])
  const [isCalendarVisible, setIsCalendarVisible] = useState(false)
  const [isSearchEnabled, setIsSearchEnabled] = useState(false)
  const [formattedBookingDates, setFormattedBookingDates] = useState(``)

  /**
   * Sets up event handlers and checks if the search is enabled on each render
   * @author Josh Smith <josh@batch.nz>
   */
  useEffect(() => {
    document.addEventListener(`click`, handleOutsideClick)
    checkIsSearchEnabled()
    return () => {
      document.removeEventListener(`click`, handleOutsideClick)
    }
  })

  /**
   * Handles when a click occurs outside the booking form on the document
   * @author Josh Smith <josh@batch.nz>
   */
  function handleOutsideClick(e) {
    if (calendarWrapperRef && !calendarWrapperRef.contains(e.target)) {
      setIsCalendarVisible(false)
    }
  }

  function handleCalendarFocus() {
    setIsCalendarVisible(true)
  }

  function handleCalendarChange(date) {
    setIsCalendarVisible(false)
    setBookingDates(date)
    setFormattedBookingDates(getFormattedBookingDates(date))
  }

  function handleNumPeopleChange(e) {
    setNumPeople(e.target.value)
  }

  /**
   * Handles clicking on the search button
   * Opens the booking service in a new window
   * @author Josh Smith <josh@batch.nz>
   * @param  object   e Event object
   * @return void
   */
  function handleSearchClick(e) {
    e.preventDefault()
    if (!isSearchEnabled) return null

    const bookingUrl = getBookingUrl()
    window.open(bookingUrl, `_blank`, `noopener`)
  }

  /**
   * Checks whether the search button should be enabled or not
   * Criteria for being enabled is check in/out date is selected and number of people > 0
   * @author Josh Smith <josh@batch.nz>
   */
  function checkIsSearchEnabled() {
    const searchEnabled = bookingDates.length && numPeople !== ''
    setIsSearchEnabled(searchEnabled)
  }

  /**
   * Returns a booking url used to send users off to an external booking service
   * @author Josh Smith <josh@batch.nz>
   * @param  Array  dates An array of Date objects
   * @return string       Booking URL
   */
  function getBookingUrl() {
    if (bookingDates.length === 0) return ``

    const checkInDate = dayjs(bookingDates[0]).format(bookingDateFormat)
    const checkOutDate = dayjs(bookingDates[1]).format(bookingDateFormat)

    return `${bookingUrl}?locale=en&items[0][adults]=${numPeople}&items[0][children]=0&items[0][infants]=0&currency=NZD&checkInDate=${checkInDate}&checkOutDate=${checkOutDate}`
  }

  /**
   * Returns a formatted string used to display selected booking dates
   * @author Josh Smith <josh@batch.nz>
   * @param  array dates An array of Date objects
   * @return string
   */
  function getFormattedBookingDates(bookingDates = []) {
    if (bookingDates.length === 0) return ``

    const startDate = dayjs(bookingDates[0]).format(displayDateFormat)
    const endDate = dayjs(bookingDates[1]).format(displayDateFormat)

    return `${startDate}–${endDate}`
  }

  /**
   * Sets the calendar wrapper ref instance property
   * This is used to determine whether a click has occurred outside of the calendar component
   * @author Josh Smith <josh@batch.nz>
   */
  function setCalendarWrapperRef(node) {
    calendarWrapperRef = node
  }

  return (
    <form ref={setCalendarWrapperRef} className="flex flex-wrap">
      {isCalendarVisible && (
        <Calendar
          className={[`absolute`, `z-50`]}
          selectRange={true}
          minDate={new Date()}
          onChange={handleCalendarChange}
          value={bookingDates.length === 0 ? null : bookingDates}
        />
      )}
      <div className="flex flex-wrap w-full xl:w-3/4 xl:pr-2">
        <div className="w-full xl:w-1/2">
          <div className="relative xl:mr-2 mb-2">
            <input
              aria-label="Select check-in/out dates"
              className="input lg:text-lg"
              value={formattedBookingDates}
              readOnly={true}
              placeholder="Select check-in/out dates"
              onFocus={handleCalendarFocus}
            />
            <div className="absolute right-0 inset-y-0 w-5 flex items-center mr-5 pointer-events-none z-10">
              <svg
                className="w-5 h-5 text-grey fill-current"
                viewBox="0 0 19.52 19.97"
              >
                <path d="M7.05,11a.45.45,0,0,0-.45-.45H5a.45.45,0,0,0-.45.45V12.6a.45.45,0,0,0,.45.45H6.6a.45.45,0,0,0,.45-.45Zm4,0a.45.45,0,0,0-.45-.45H9a.45.45,0,0,0-.45.45V12.6a.45.45,0,0,0,.45.45h1.58A.45.45,0,0,0,11,12.6Zm4,0a.46.46,0,0,0-.46-.45H12.92a.45.45,0,0,0-.45.45V12.6a.45.45,0,0,0,.45.45H14.5A.46.46,0,0,0,15,12.6ZM7.05,15a.46.46,0,0,0-.45-.46H5a.46.46,0,0,0-.45.46v1.58A.45.45,0,0,0,5,17H6.6a.45.45,0,0,0,.45-.45Zm4,0a.46.46,0,0,0-.45-.46H9a.46.46,0,0,0-.45.46v1.58A.45.45,0,0,0,9,17h1.58a.45.45,0,0,0,.45-.45Zm4,0a.47.47,0,0,0-.46-.46H12.92a.46.46,0,0,0-.45.46v1.58a.45.45,0,0,0,.45.45H14.5a.46.46,0,0,0,.46-.45ZM17.78,2.22V4.64a2,2,0,0,1-2,2H14.56a2,2,0,0,1-2-2V2.21H7V4.64a2,2,0,0,1-2,2H3.71a2,2,0,0,1-2-2V2.22A1.8,1.8,0,0,0,0,4V18.17A1.8,1.8,0,0,0,1.79,20H17.73a1.8,1.8,0,0,0,1.79-1.8V4A1.8,1.8,0,0,0,17.78,2.22Zm-.57,15.06a.78.78,0,0,1-.78.78H3.06a.78.78,0,0,1-.78-.78V10a.78.78,0,0,1,.78-.77H16.43a.78.78,0,0,1,.78.77Zm-13.5-12H4.94a.67.67,0,0,0,.68-.67v-4A.67.67,0,0,0,4.94,0H3.71A.68.68,0,0,0,3,.68v4A.68.68,0,0,0,3.71,5.31Zm10.83,0h1.24a.68.68,0,0,0,.68-.67v-4A.68.68,0,0,0,15.78,0H14.54a.67.67,0,0,0-.67.68v4A.67.67,0,0,0,14.54,5.31Z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="w-full xl:w-1/2">
          <div className="select lg:text-lg mb-2">
            <select
              aria-label="How many people?"
              value={numPeople}
              onChange={handleNumPeopleChange}
              onBlur={handleNumPeopleChange}
            >
              <option default disabled="disabled" value="">
                How many people?
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
            </select>
            <div className="absolute right-0 inset-y-0 w-4 flex items-center mr-5 pointer-events-none z-50">
              <svg
                className="w-4 h-4 text-grey fill-current"
                viewBox="0 0 13 7"
              >
                <path d="M6.5,7,0,0H13Z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <button
        disabled={!isSearchEnabled}
        onClick={handleSearchClick}
        className={`button-primary w-full xl:w-auto xl:w-1/4 lg:min-w-0 lg:px-12 ${
          !isSearchEnabled ? `opacity-50 pointer-events-none` : ``
        }`}
      >
        Search
      </button>
    </form>
  )
}

export default BookingForm
